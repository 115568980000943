<template>
  <div class="searchInfo">
    <div class="content">
      <van-tabs v-model="active" @click="tabClick" color="#0379ff">
        <van-tab title="门诊用药" name="3"></van-tab>
        <van-tab title="热门医院" name="1"></van-tab>
        <van-tab title="在线配镜" name="2"></van-tab>
      </van-tabs>
      <div v-if="active == '1' ">
          <vaeHospital @hospitalDetail="clickHospitalList" :hosList="hotHospitalList" v-if="hotHospitalList.length > 0" ></vaeHospital>
          <div v-else style="height:100vh;"><van-empty description="暂无更多内容" /></div>
      </div>
      <div v-if="active == '2' ">
          <vaeHospital @hospitalDetail="clickHospitalList" :hosList="glassesList"  v-if="glassesList.length > 0"></vaeHospital>
          <div v-else><van-empty description="暂无更多内容" /></div>
      </div>
      <div v-if="active == '3' ">
          <vaeGoods :goodsList="product_list" @buyGoods="buyGoods" v-if="product_list.length > 0"></vaeGoods>
          <div v-else><van-empty description="暂无更多内容" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import vaeHospital from '@/components/hospitalList.vue'
import vaeGoods from '@/components/hotGoods.vue'
import { search } from '@/api/studentProduct'
export default {
    components: {
    vaeHospital,
    vaeGoods
  },
  data () {
    return {
      search_word: '',
      active:'3',
      hotHospitalList:[],
      glassesList:[],
      product_list:[],
      startPage:1
    }
  },
  created () {
    this.search_word = this.$route.query.search_word
    this.getResultList(this.search_word,this.active,this.startPage)
  },
  methods: {
    async getResultList (search_word,type,page) {
      const res = await search({search_word:search_word,type:parseInt(type),page:page})
      if(res.data.code == 200) {
          if(this.active == '1') {
              this.hotHospitalList = res.data.data.list
              this.glassesList = []
              this.product_list = []
          }
          if(this.active == '2') {
              this.glassesList = res.data.data.list
              this.hotHospitalList = []
              this.product_list = []
          }
          if(this.active == '3') {
              this.product_list = res.data.data.list
              this.hotHospitalList = []
              this.glassesList = []
          }
      }
    },
    tabClick (name) {
        this.getResultList(this.search_word,name,this.startPage)
    },
    clickHospitalList (val) {
        setTimeout(() => {
        this.$router.push({
          path: '/hospitalInfo',
          query: {
            id: val.id
          }
        })
      }, 500)
    },
    clickGlasses () {},
    buyGoods (val) {
      this.$router.push({
        path: '/drugDetail',
        query: {
          id: val.id
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.searchInfo {
  .content {
      height: 100vh;
  }
}
</style>
